import React from 'react';
import classNames from 'classnames';

const Loader = ({ color }) => (
  <div className={classNames('lds-ring', color === 'primary' && 'primary')}>
    <div />
    <div />
    <div />
    <div />
  </div>
)
export default Loader
