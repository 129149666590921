import React, { useRef, useState } from 'react';

import get from 'lodash/get';
import classNames from 'classnames';

import Loader from '../../components/Loader';

import { Field } from 'formik';

import uploadImage from '../../client/utils/uploadImage';

import './ImageUpload.module.scss';

const ImageUpload = ({
  token,
  label,
  fieldName,
  placeholder,
  setFieldValue,
  errors,
  touched,
  isOptional,
  isNormalForm,
  value,
  emitImageValue,
}) => {
  const [isLoading, setLoading] = useState(false);
  const innerRef = useRef();

  const useFormikField = (children) => {
    return <Field name={fieldName}>
      {({ field }) => (
        children(field)
      )}
    </Field>
  }

  const useNormalField = (children) => {
    return (
      children()
    );
  }

  const children = (field) => {
    const fieldValue = get(field, 'value', value);

    return (
      <div>
        <p className="field__label">{label} {!isOptional && <span className="color-lp-red">*</span>}</p>
        <div className={classNames('column__box', fieldValue && 'uploaded')}>
          {
            fieldValue
              ? (
                <div className="column__box--image" style={{ backgroundImage: `url('${fieldValue}')` }}>
                  <div
                    className="image__close"
                    onClick={() => {
                      if (setFieldValue) {
                        setFieldValue(fieldName, '');
                        return;
                      }

                      emitImageValue('');
                    }}
                  >
                    <i className="icon ion-md-close" />
                  </div>
                </div>
              )
              : isLoading
                ? <Loader color="primary" />
                : (
                  <div
                    className="column__box--content"
                    style={{
                      backgroundImage: `url(${placeholder})`
                    }}
                  >
                    <div className="content__upload" onClick={() => !fieldValue && innerRef.current.click()}>
                      <i className="lifepal-download" />
                    </div>
                  </div>
                )
          }
        </div>
        <input
          className="d-none"
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          ref={innerRef}
          onClick={(event) => event.target.value = null}
          onChange={event => {
            event.stopPropagation();
            event.preventDefault();

            setLoading(true);

            uploadImage(token, event.target.files[0])
              .then((imageUrl) => {
                if (setFieldValue) {
                  setFieldValue(fieldName, imageUrl);
                } else {
                  emitImageValue(imageUrl);
                }

                setLoading(false);
              });
          }}
        />
        {(get(touched, fieldName) && get(errors, fieldName)) && (
          <p className="heading-xs-r color-lp-red">{get(errors, fieldName)}</p>
        )}
      </div>
    );
  }

  if (isNormalForm) {
    return useNormalField(children);
  }

  return useFormikField(children);
};

export default ImageUpload;
