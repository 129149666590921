import { quotationsRest } from '../../client';
import axios from 'axios';
import qs from 'query-string'

const uploadImage = async (token, imageFile, folderName) => {
  if (imageFile && imageFile.name && imageFile.type) {
    const queries = {
      folderName,
      fileName: imageFile.name,
    };

    const s3Object = await quotationsRest(`/quotations/fileUpload/?${qs.stringify(queries)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${token}`,
      },
    });

    await axios.put(s3Object.urls, imageFile, {
      headers: {
        'Content-Type': imageFile.type,
      }
    });

    return s3Object.urls.split('?')[0];
  }
}

export default uploadImage;
